import { auth } from '@/store/modules/auth';
import { filter } from '@/store/modules/filter';
import Vuex, { StoreOptions } from 'vuex';
import { RootState } from './types';

const store: StoreOptions<RootState> = {
  state: {
    helloMessage: 'Hello',
  },
  getters: {},
  mutations: {},
  actions: {},
  modules: { auth, filter },
};

export default new Vuex.Store<RootState>(store);
