import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createStaticVNode as _createStaticVNode } from "vue"
import _imports_0 from '@/assets/images/plant.png'
import _imports_1 from '@/assets/images/tube.png'


const _hoisted_1 = { class: "login" }
const _hoisted_2 = { class: "login__form" }
const _hoisted_3 = { class: "login__greeting" }
const _hoisted_4 = { class: "inputs" }
const _hoisted_5 = { class: "input p-input-icon-left" }
const _hoisted_6 = {
  key: 0,
  id: "login-help",
  class: "p-error"
}
const _hoisted_7 = { class: "input p-input-icon-left" }
const _hoisted_8 = {
  key: 0,
  id: "password-help",
  class: "p-error"
}
const _hoisted_9 = { class: "input" }
const _hoisted_10 = { class: "google" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InputText = _resolveComponent("InputText")!
  const _component_Button = _resolveComponent("Button")!
  const _component_NotificationToast = _resolveComponent("NotificationToast")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[8] || (_cache[8] = _createElementVNode("div", { class: "login__bg" }, [
      _createElementVNode("img", {
        src: _imports_0,
        alt: "plant img"
      })
    ], -1)),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _cache[6] || (_cache[6] = _createElementVNode("div", { class: "hello-container" }, [
          _createElementVNode("img", {
            class: "image",
            src: _imports_1,
            alt: "cosmios logo"
          }),
          _createElementVNode("h1", null, "Hello Again!"),
          _createElementVNode("p", null, "Log in to your account")
        ], -1)),
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _cache[3] || (_cache[3] = _createElementVNode("i", { class: "pi pi-user" }, null, -1)),
            _createVNode(_component_InputText, {
              class: _normalizeClass(["input-style p-inputtext-lg", { 'p-invalid': _ctx.v$.login.$error }]),
              placeholder: "Login",
              type: "text",
              modelValue: _ctx.state.login,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.state.login) = $event))
            }, null, 8, ["modelValue", "class"]),
            (_ctx.v$.login.$error)
              ? (_openBlock(), _createElementBlock("small", _hoisted_6, " Please provide login. "))
              : _createCommentVNode("", true)
          ]),
          _createElementVNode("div", _hoisted_7, [
            _cache[4] || (_cache[4] = _createElementVNode("i", { class: "pi pi-lock" }, null, -1)),
            _createVNode(_component_InputText, {
              class: _normalizeClass(["input-style p-inputtext-lg", { 'p-invalid': _ctx.v$.password.$error }]),
              placeholder: "Password",
              type: "password",
              modelValue: _ctx.state.password,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.state.password) = $event))
            }, null, 8, ["modelValue", "class"]),
            (_ctx.v$.password.$error)
              ? (_openBlock(), _createElementBlock("small", _hoisted_8, " Please provide password. "))
              : _createCommentVNode("", true)
          ]),
          _createElementVNode("div", _hoisted_9, [
            _createVNode(_component_Button, {
              class: "p-button-lg",
              label: "Sign in",
              onClick: _ctx.submit
            }, null, 8, ["onClick"])
          ])
        ]),
        _cache[7] || (_cache[7] = _createElementVNode("div", { style: {"display":"flex","justify-content":"space-between","width":"100%","align-items":"center","margin-top":"-10px"} }, [
          _createElementVNode("div", { style: {"width":"45%","height":"1px","background":"#7a8f85"} }),
          _createElementVNode("p", null, "or"),
          _createElementVNode("div", { style: {"width":"45%","height":"1px","background":"#7a8f85"} })
        ], -1)),
        _createElementVNode("div", _hoisted_10, [
          _createElementVNode("div", {
            class: "google__button",
            onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.authUserGoogle && _ctx.authUserGoogle(...args)))
          }, _cache[5] || (_cache[5] = [
            _createStaticVNode("<svg xmlns=\"http://www.w3.org/2000/svg\" width=\"18\" height=\"18\" viewBox=\"0 0 18 18\" fill=\"none\" data-v-3bd9707e><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M18 9.20459C18 8.5664 17.9416 7.95277 17.833 7.36368H9.18367V10.845H14.1262C13.9133 11.97 13.2662 12.9232 12.2936 13.5614V15.8196H15.2616C16.9981 14.2528 18 11.9455 18 9.20459Z\" fill=\"#4285F4\" data-v-3bd9707e></path><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M9.18368 18C11.6633 18 13.7421 17.1941 15.2616 15.8196L12.2936 13.5614C11.4712 14.1014 10.4193 14.4204 9.18368 14.4204C6.79175 14.4204 4.76717 12.8372 4.045 10.71H0.976813V13.0418C2.48795 15.9832 5.5937 18 9.18368 18Z\" fill=\"#34A853\" data-v-3bd9707e></path><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M4.045 10.71C3.86132 10.17 3.75696 9.59321 3.75696 9.00003C3.75696 8.40685 3.86132 7.83004 4.04499 7.29004V4.95822H0.976809C0.354824 6.17322 0 7.54776 0 9.00003C0 10.4523 0.354828 11.8268 0.976813 13.0418L4.045 10.71Z\" fill=\"#FBBC05\" data-v-3bd9707e></path><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M9.18368 3.57954C10.532 3.57954 11.7426 4.03363 12.6944 4.92545L15.3284 2.34409C13.738 0.891817 11.6591 0 9.18368 0C5.5937 0 2.48794 2.01686 0.976809 4.95822L4.04499 7.29004C4.76716 5.16277 6.79175 3.57954 9.18368 3.57954Z\" fill=\"#EA4335\" data-v-3bd9707e></path></svg><span style=\"margin-left:10px;\" data-v-3bd9707e>Sign in with Google</span>", 2)
          ]))
        ])
      ])
    ]),
    _createVNode(_component_NotificationToast)
  ]))
}