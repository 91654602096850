import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "toast-container" }
const _hoisted_2 = { class: "toast-summary" }
const _hoisted_3 = { class: "toast-detail" }
const _hoisted_4 = {
  key: 0,
  class: "grid p-fluid toast-buttons"
}
const _hoisted_5 = { class: "col-6" }
const _hoisted_6 = { class: "col-6" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!
  const _component_Toast = _resolveComponent("Toast")!

  return (_openBlock(), _createBlock(_component_Toast, null, {
    message: _withCtx((slotProps) => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _cache[0] || (_cache[0] = _createElementVNode("i", {
            class: "pi pi-exclamation-triangle",
            style: {"font-size":"3rem"}
          }, null, -1)),
          _createElementVNode("h4", null, _toDisplayString(slotProps.message.summary), 1),
          _createElementVNode("div", _hoisted_3, _toDisplayString(slotProps.message.detail), 1),
          (slotProps.message.severity === 'info')
            ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                _createElementVNode("div", _hoisted_5, [
                  _createVNode(_component_Button, {
                    class: "p-button-success",
                    label: "Keep",
                    onClick: _ctx.onConfirm
                  }, null, 8, ["onClick"])
                ]),
                _createElementVNode("div", _hoisted_6, [
                  _createVNode(_component_Button, {
                    class: "p-button-secondary",
                    label: "Reset",
                    onClick: _ctx.onReset
                  }, null, 8, ["onClick"])
                ])
              ]))
            : _createCommentVNode("", true)
        ])
      ])
    ]),
    _: 1
  }))
}